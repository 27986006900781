import { SAVE_USER, UPDATE_USER } from "../actions/actionTypes";
import CookieHelper from "../../Helpers/Cookies";

const initialState = {
    userData: {} 
};

export const userReducer = (state = initialState, action)  =>{
    switch(action.type){
        case SAVE_USER:
            return {
                ...state,
                userData: action.payload
            }
        case UPDATE_USER:
            let newUser = {...state.userData, ...action.payload};
            CookieHelper.setCookie(newUser.orgId + '.' + newUser.userId, JSON.stringify(newUser));
            return {
                ...state,
                userData: newUser,
            }
        default:
            return state;
    }
}