import log from 'loglevel';
import { FETCH_POSTS, SAVE_USER, UPDATE_USER } from './actionTypes';

export const saveUser = (user) => dispatch => {
    log.debug("Saving User to Store:", JSON.stringify(user));
    dispatch({
        type: SAVE_USER,
        payload: user
    })
}

export const updateUser = (user) => dispatch => {
    log.debug("Updating User in Store:", JSON.stringify(user));
    dispatch({
        type: UPDATE_USER,
        payload: user
    })
}