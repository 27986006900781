import React, { Component } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class Loading extends Component {
    render() {
        return (
            <div style={{minHeight: '50px'}}>
                <div className="loginLoader">
                    <FontAwesomeIcon icon={faCircleNotch} spin={true} size="4x" color="#009EDB" />
                    <h1>{ this.props.message ? this.props.message : 'Loading'}</h1>
                </div>
            </div>
        )
    }
}
