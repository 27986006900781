import React, { Component, Suspense } from 'react';

import ViewContentWindow from './Views/Learn/ViewContentWindow';
import LoginScreen from './Views/Login/LoginScreen';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router';

import { history } from './Redux/store';
import LoginCheck from './LoginCheck';
import Loading from './Views/Admin/Components/Loading';
import NotFound from './Views/Admin/Components/NotFound';
import LoginError from './Views/Admin/Components/LoginError';

const AdminView = React.lazy(() => import('./Views/Admin/AdminView'));
const TestHome = React.lazy(() => import('./Views/Test/TestHome'));
const HelpList = React.lazy(() => import('./Views/Learn/HelpList'));

class Routes extends Component {
    render() {
        return (
            <ConnectedRouter history={history}>
              <LoginCheck/>
              <Switch>
                <Route exact path="/" component={Loading} />
                <Route path="/login/error" component={LoginError}/>
                <Route path="/login" component={LoginScreen}/>
                <Suspense path="/learn" fallback={<Loading message='Loading Help Panel'/>}>
                    <Route path="/learn" component={HelpList}/>
                </Suspense>
                <Suspense path="/test" fallback={<Loading message='Loading Testing Application'/>}>
                    <Route path="/test/:testID" component={TestHome}/>
                </Suspense>
                <Route path="/view/:layoutID/:contentID" component={ViewContentWindow}/>
                <Suspense path="/admin" fallback={<Loading message='Loading Admin Panel'/>}>
                    <Route path="/admin" component={AdminView}/>
                </Suspense>


                <Route component={NotFound}/>
              </Switch>
          </ConnectedRouter>
        )
    }
}



export default (Routes);
