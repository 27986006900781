import React from 'react'
import Error from './Error'

export default function LoginError() {

    let error = {
        status: 401,
        data: {
            message: 'Unauthorized User'
        }
    }
    return (
        <Error error={error}/>
    )
}
