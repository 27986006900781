import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';


export const history = createBrowserHistory();

const initialState = {
    
};

const middleWare = [thunk];

export default function configureStore() {
    const store = createStore(
      rootReducer(history), // root reducer with router state
      initialState,
      compose(
        applyMiddleware(
          routerMiddleware(history), 
          ...middleWare
        ),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      ),
    )
  
    return store
  }