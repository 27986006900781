import { instanceOf, PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import CookieHelper from './Helpers/Cookies';
import { saveUser, updateUser } from './Redux/actions/userActions';
import { API_URL } from './config';
import Axios from 'axios';
import Error from './Views/Admin/Components/Error';
import log from 'loglevel';
import Loading from './Views/Admin/Components/Loading';




var jwtDecode = require('jwt-decode');


class LoginCheck extends Component {

    constructor(props){
        super(props);
        this.state = { 
            error: false
        }

    }

    componentDidMount(){
        this.updateLogin();
    }

    checkForUpdates(){
        Axios.get(API_URL + '/checkforupdates/', { headers: {jwttoken: this.props.user.userData.token}})
        .then(success => {
            // success.data.user.roleId = success.data.user.RoleId
            this.props.updateUser(success.data.user);
            log.debug('API Response:', success)
        }).catch(error => {
            if(error.response){
                log.error('API Error:', error)
                if(error.response.status === 401 && error.response.data.message === 'Unauthorized User'){
                    this.setState({error: true})
                }
            }
        })
    }


    updateLogin(){
        let cookieKey;
        let query = this.props.router.query;
        if(query.OrganizationId && query.UserId){
            log.trace('User Specified');
            if(CookieHelper.getCookie(query.OrganizationId + '.' + query.UserId)){
                cookieKey = query.OrganizationId + '.' + query.UserId;
                CookieHelper.setCookie('cur_user', cookieKey);
            }
        }else if(CookieHelper.getCookie('cur_user')){
            log.trace('Using Current User')
            if(CookieHelper.getCookie(CookieHelper.getCookie('cur_user'))){
                log.trace('Cookie Valid')
                cookieKey = CookieHelper.getCookie('cur_user');
            }
        }else{
            log.trace('User not logged in. Redirecting.')
            this.redirectToLogin();
            return;
        }
        if(cookieKey){
            let user = JSON.parse(CookieHelper.getCookie(cookieKey));
            log.debug('Logged in User Details:', user);
            let exp = jwtDecode(user.token).exp;
            let now = new Date().getTime() / 1000;
            if(exp < now){
                log.trace('User token expired.');
                this.redirectToLogin();
            }else{
                this.props.saveUser(user);
                log.trace('User token vald.');
                this.interval = setTimeout(() => {
                    if(this.props.user.userData.token){
                        this.checkForUpdates();
                    }
                }, 100);
            }
        }else{
            log.trace('user not logged in. Redirecting to login.');
            this.redirectToLogin();
        }
    }

    redirectToLogin(){
        let prevRouter = this.props.router;
        if(this.props.router.pathname.includes('/login')){
            prevRouter = null;
        }
       this.props.history.push({pathname: '/login', search: this.props.router.search, state: {prevRouter: prevRouter}});
        // this.setState({ redirectToLogin: true }, () => { });
    }

    render() {
        if(this.state.error !== false){
            return (<Redirect to={{ pathname: "/login/error"}} />);
        }
        if (this.props.router.pathname === '/' && this.props.user.userData){
            if(this.props.router.query.source){
                return (<Redirect to={{ pathname: "/learn", search: this.props.router.search }} />);
            }else{
                return (<Redirect to={{ pathname: "/admin/dashboard", search: this.props.router.search }} />);
            }
        }else if(this.props.user.userData){
            return(null)
        }else{
            return <Loading message='Checking Login'/>
        }
    }
}

LoginCheck.propTypes = {
    user: PropTypes.object.isRequired,
    saveUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    router: state.router.location,
    
})


export default withRouter(connect(mapStateToProps, { saveUser, updateUser })(LoginCheck));
