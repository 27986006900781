import log from "loglevel";

// SET TO TRUE IF RUNNING REACT LOCALLY. SET TO FALSE IF RUNNING REACT OVER HTTPS
export let LOCALHOST = false;

//SET TO 'qa', 'dev', or 'prod'. Defaults to dev if invalid value is used.
export let AWS_ENVIRONMENT = 'prod';

//API ADDRESSES DEFINED
let PROD_API_URL = 'https://api.digadop.com'
let DEV_API_URL = 'https://dev-api.digadop.com'
let QA_API_URL = 'https://qa-api.digadop.com'
let LOCAL_API_URL = 'http://localhost:3001'




// **********************************************************
// NOTHING BELOW THIS SHOULD BE TOUCHED DURING CONFIGURATION.
// **********************************************************

//LOGIC TO RETURN APPROPRIATE KEYS / URLS
function GET_API_URL() {
    if(AWS_ENVIRONMENT.toLowerCase() === 'prod'){
        return PROD_API_URL
    }else if(AWS_ENVIRONMENT.toLowerCase() === 'qa'){
        return QA_API_URL
    }else if(AWS_ENVIRONMENT.toLowerCase() === 'dev'){
        return DEV_API_URL
    }else if(AWS_ENVIRONMENT.toLowerCase() === 'local'){
        return LOCAL_API_URL
    }else{
        log.error("INVALID AWS ENVIRONMENT VALUE. MUST BE 'DEV', 'PROD', OR 'QA'. DEFAULTING TO DEV")
        return DEV_API_URL
    }
}

//VALUES USED IN CLASSES
export const API_URL = GET_API_URL()