import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { API_URL } from '../../config';
import Error from '../Admin/Components/Error';
import Loading from '../Admin/Components/Loading';



const axios = require('axios').default;

class ViewContentWindow extends Component {

    sendingLog = false;


    constructor(props){
        super(props);
        let params = false
        if(this.props.match){
            log.debug('has params')
            params = this.props.match.params
        }else{
            log.debug('no params', this.props.content)
        }
        let contentID = params ? params.contentID : this.props.content.ContentId
        let layoutID = params ? params.layoutID : 0;
        log.debug(contentID)
        this.state = {
            error: false,
            loading: params,
            resizedAt: false,
            logOverTime: true,
            filePreview: params ? false : true,
            contentID: contentID,
            layoutID: layoutID,
            content: params ? false : this.props.content,
            fileContent: false,
            logID: false,
            videoSpeed: params ? 1 : 10
        }

        log.debug(this.props.content, params)
    }

    componentDidUpdate(prev){
        if(this.props.user.userData && prev.user.userData !== this.props.user.userData && !this.state.content){
            this.getContent();
        }else if(this.props.user.userData && prev.user.userData !== this.props.user.userData && this.state.content && this.state.content.ContentType == 'Html'){
            this.getFileContents();
        }
    }

    componentDidMount(){
        if( this.props.user.userData && this.state.content){
            if(this.state.content.ContentType  === 'Html'){
                this.getFileContents();
            }
        }
        window.addEventListener('resize', this.handleResize.bind(this))
    }

    handleResize(){
        log.debug('resizing');
        this.setState({resizedAt: new Date()});
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize.bind(this));
        window.clearInterval(this.interval);
    }

    startTimer(){
        if(!this.interval && this.state.layoutID > 1){
            this.interval = window.setInterval(() => {
                this.handleLog()
            }, 1000)
        }
    }

    handleLog(){
        if(this.sendingLog || this.state.error !== false || this.state.layoutID < 1){
            return;
        }
        if(this.state.logID  && this.state.logOverTime){
            //PUT
            let body = []
            if(this.state.content){
                if(this.state.content.elapsedTime){
                    body.push({LogId: this.state.logID, ElapsedTime: this.state.content.elapsedTime});
                }else{
                    body.push({LogId: this.state.logID})
                }
            }
            let headers = {
                jwttoken: this.props.user.userData.token
            };
            this.sendingLog = true;
            Axios.put(API_URL + '/log', body, { headers: headers })
            .then(success => {
                log.debug(success.data);
                this.sendingLog = false;
            }).catch(error => {
                this.sendingLog = false;
                log.error(error);
            });
        }
        if((!this.state.logID && this.state.content)){
            //POST
            let body = [];
            const params = {};
            let searchParams = new URLSearchParams(this.props.router.query);
            for (const [key, val] of searchParams.entries()){
                params[key] = decodeURI(val).replace('+', ' ');
            }
            if(!this.state.logID && this.state.content){
                body.push({
                    RequestType: "GET",
                    LogType: "VIEW_CONTENT",
                    ContentId: this.state.content.ContentId,
                    PageLayoutId: this.state.layoutID,
                    ProfileName: params.ProfileName ? params.ProfileName : null,
                    ProfileId: params.ProfileId ? params.ProfileId : null,
                    PageName: params.PageName ? params.PageName : null,
                    ObjectType: params.ObjectType ? params.ObjectType : null,
                    RecordType: params.RecordType ? params.RecordType : null,
                    CurrentApplication: params.Application ? params.Application: null,
                    UserInterfaceType: params.UI ? params.UI: null
                })
            }
            if(params.SuperAutoPlay === 'true'){
                body.push({
                    RequestType: "GET",
                    LogType: "SuperAutoPlay",
                    PageLayoutId: this.state.layoutID,
                    ContentId: this.state.content.ContentId,
                    ProfileName: params.ProfileName ? params.ProfileName : null,
                    ProfileId: params.ProfileId ? params.ProfileId : null,
                    PageName: params.PageName ? params.PageName : null,
                    ObjectType: params.ObjectType ? params.ObjectType : null,
                    RecordType: params.RecordType ? params.RecordType : null,
                    CurrentApplication: params.Application ? params.Application: null,
                    UserInterfaceType: params.UI ? params.UI: null
                })
            }
            let headers = {
                jwttoken: this.props.user.userData.token,
            };
            this.sendingLog = true;
            Axios.post(API_URL + '/log', body, { headers: headers })
            .then(success => {
                log.debug(success.data);
                if(!this.state.logID && this.state.content){
                    this.setState({
                        logID: success.data[0].LogId
                    })
                }
                this.sendingLog = false;
            }).catch(error => {
                log.error(error)
                this.sendingLog = false;
            });
        }
    }

    getContent(){
        this.setState({
            loading: true,
            error: false,
            logOverTime: true,
        })
        let headers = {
            jwttoken: this.props.user.userData.token
        }
        log.debug(headers)
        axios.get(API_URL + '/content/' + this.state.contentID, { headers: headers })
        .then(success => {
            log.debug(success);
            this.setState({
                content: success.data[0],
                loading: success.data[0].ContentType !== 'Html' ? false : true
            })
            if(success.data[0].ContentType === 'Html'){
                this.getFileContents();
            }else if(!this.state.filePreview){
                this.startTimer();
            }
             if(success.data[0].ContentType === 'Attachment'){
                let type = this.getAttachmentType(success.data[0].ContentLink, success.data[0].ContentMimeType);
                if(!type){
                    this.setState({
                        logOverTime: false
                    });
                }
            }
        }).catch(error => {
            log.error(error);
            this.setState({
                loading: false,
                error: error.response ? error.response : true
            })
        });
    }

    getFileContents(){
        let headers = {
            jwttoken: this.props.user.userData.token ,
        }
        log.debug(headers);
        axios.get(API_URL + '/filedownload/' + this.state.contentID, {
            headers: headers
        }).then(success => {
            log.debug(success);
            this.setState({
                fileContent: success.data.fileContents,
                loading: false,
            })
            this.startTimer();
        }).catch(error => {
            log.error(error);
            this.setState({
                loading: false,
                error: error.response ? error.response : true
            })
        });
    }

    updateVideoProgress(data){
        let content = this.state.content
        content.elapsedTime = data.playedSeconds
        this.setState({content: content})
    }

    rotateSpeed(){
        if(this.state.videoSpeed  === 1){
            this.setState({videoSpeed: 1.25});
        }else if(this.state.videoSpeed  === 1.25){
            this.setState({videoSpeed: 1.5});
        }else if(this.state.videoSpeed  === 1.5){
            this.setState({videoSpeed: 2});
        }else if(this.state.videoSpeed  === 2){
            this.setState({videoSpeed: 1});
        }
    }

    getAttachmentType(url, mimeType){
        log.debug(mimeType);
        //Infer from URL if mimeType is null
        if(!mimeType){
            let ext = url.substring(url.lastIndexOf('.'));
            url.substring(url.lastIndexOf('.'));
            if(ext.length > 5 && url.indexOf('?') > 3){
                ext = ext.substring(0, ext.indexOf('?'));
            }
            log.debug("File Extension: ", ext);
            let imageExtensions = [".jpg", ".bmp", ".gif", ".jpeg", ".png", ".svg", ".tif", ".tiff", ".webp"];
            let viewableExtensions = [".html", ".txt", ".pdf"];
            if(imageExtensions.includes(ext)){
                return 'image';
            }else if(viewableExtensions.includes(ext)){
                return 'viewableDoc';
            }else{
                return false;
            }
        }else{
            log.debug(mimeType)
            //Use MimeType from DB
            let imageTypes = ['image/png', 'image/bmp', 'image/gif', 'image/jpg', 'image/jpeg'];
            let viewableTypes = ['application/pdf', 'text/html', 'text/plain'];
            if(imageTypes.includes(mimeType)){
                return 'image';
            }else if(viewableTypes.includes(mimeType)){
                return 'viewableDoc';
            }else{
                return false;
            }
        }
    }

    render() {
        if(this.state.loading && !this.state.error){
            return (<Loading/>)
        }else if(!this.state.loading && this.state.error){
            return (<Error error={this.state.error} tryAgain={this.getContent.bind(this)}/>);
        }else{
            if(this.state.content.ContentType === 'Html'){
                // return (<div style={{height: '100%'}}>
                //         <embed src={this.state.content.ContentLink + '#view=FitH'} width={this.state.filePreview ? '400px' : window.innerWidth} height={this.state.filePreview ? '400px' : window.innerHeight} style={{overflow: 'hidden', backgroundColor: white}} scrolling='no'/>
                //     </div>)
                return (
                    <div style={{width: this.state.filePreview ? '100%' : '100%', height: this.state.filePreview ? '400px' : window.innerHeight, overflowY: 'auto', backgroundColor: 'white'}} className='ql-editor'>
                        <div dangerouslySetInnerHTML={{__html: this.state.fileContent}} style={{paddingTop: '10px', fontSize: this.state.filePreview ? '8px' : '12px', lineHeight: 'normal', backgroundColor: 'white'}}></div>
                    </div>
                );
            }else if(this.state.content.ContentType === 'URL'){
                window.location = (this.state.content.ContentLink);
                return (
                    <Loading message='Redirecting...'/>
                );
            }else if(this.state.content.ContentType === 'Video'){
                return (<div className="player-wrapper">
                            {/* <video key={this.state.content.ContentLink} controls  width="100%" height="280px" autoPlay='true'>
                                <source src={this.state.content.ContentLink} width="100%"/>
                                Your browser cannot play this video type.
                            </video> */}
                            <ReactPlayer url={this.state.content.ContentLink} controls className='player'
                            width='100%' height='100%'
                            config={{
                                youtube: { 
                                    playerVars: { height: '500' }
                                }
                            }}
                            playbackRate={this.state.videoSpeed} 
                            onProgress={this.updateVideoProgress.bind(this)} 
                            playing={true} muted={false} playing={true} loop={this.state.filePreview ? true : false}/>
                            <h3 style={{position: 'absolute', top: '2px', right: '10px', color: 'white', cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.5)'}} onClick={this.rotateSpeed.bind(this)}>{this.state.videoSpeed}x</h3>
                        </div>);
            }else if(this.state.content.ContentType === 'Attachment'){

                let type = this.getAttachmentType(this.state.content.ContentLink, this.state.content.ContentMimeType)
                if(type === 'image'){
                    return (<div style={{height: '100%'}}>
                    <img src={this.state.content.ContentLink} width={this.state.filePreview ? '400px' : '100%'} height={'auto'} style={{overflow: 'hidden'}}/>
                </div>)
                }else if (type === 'viewableDoc'){
                    //Generic Embed for Documents
                    return (<div style={{height: '100%'}}>
                        <embed src={this.state.content.ContentLink + '#view=FitH'} width={this.state.filePreview ? '400px' : window.innerWidth} height={this.state.filePreview ? '400px' : window.innerHeight} style={{overflow: 'hidden'}} scrolling='no'/>
                    </div>)
                }else{
                    //Download page for unviewable documents
                    return (<div className='centerVert card' style={{width: '50%', minWidth: '300px'}}>
                            <div className='card-detail'>
                                <h3 className='text-muted'>{this.state.content.ContentName}</h3>
                                <FontAwesomeIcon icon={faDownload} size='4x' className='text-primary mt-2'/>
                                <h2>We are unable to preview this file for you.</h2>
                                <p className='text-muted'>You will need to download it and open it in the correct application.</p>
                                <a href={this.state.content.ContentLink} download className='btn btn-primary btn-lg mt-3'>Download Now</a>
                            </div>
                        {/* <embed src={this.state.content.ContentLink} width='0' height={this.state.filePreview ? '400px' : window.innerHeight} style={{overflow: 'hidden'}} scrolling='no'/> */}
                    </div>)
                }
            }else if(this.state.content.ContentType === 'SectionHeader'){
                return <div className='p-5'>
                        <h2>{this.state.content.ContentLabel}</h2>
                        <p>{this.state.content.ContentDescription}</p>
                    </div>
            }else{
                return <h1>Error Occured</h1>
            }
        }
    }
}

ViewContentWindow.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    router: state.router.location,
})

export default connect(mapStateToProps, {  })(ViewContentWindow);