import log from 'loglevel';
import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { LOCALHOST } from './config';
import configureStore from './Redux/store';
import Routes from './Routes';
import './Styles.css';

log.setDefaultLevel(LOCALHOST ? 'TRACE' : 'WARN')
const store = configureStore();

class App extends Component { 

  render() {
    return (
      <Provider store={store}>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          <CookiesProvider>
            <Routes/>
          </CookiesProvider>
      </Provider>
    );
  }
}
export default App;
