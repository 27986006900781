import React from 'react';
import ReactDOM from 'react-dom';
import {AWS_ENVIRONMENT} from './config';
import './assets/bootstrap/dist/css/bootstrap.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if(AWS_ENVIRONMENT === 'prod'){
    serviceWorker.register();
}else{
    serviceWorker.unregister();
}
 