import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { userReducer } from './userReducer';
import { contentReducer } from './contentReducer';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    content: contentReducer,
})

export default rootReducer;