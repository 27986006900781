
export default class LocalStorageHelper {

    static saveData(name, data, user){
        let now = new Date()
        let value = {
            savedDate: now,
            data: data
        }
        localStorage.setItem(name + '.' + user.orgId + '.' + user.userId, JSON.stringify(value));
    
    }
    
    static getData(name, user){
        if(localStorage.getItem(name + '.' + user.orgId + '.' + user.userId)){
            return JSON.parse(localStorage.getItem(name + '.' + user.orgId + '.' + user.userId)).data;
        }else{
            return null;
        }
    }

    static isCacheTooOld(name, hours, user){
        if(localStorage.getItem(name + '.' + user.orgId + '.' + user.userId)){
            let stor = JSON.parse(localStorage.getItem(name + '.' + user.orgId + '.' + user.userId));
            let now = new Date();
            let date = new Date(stor.savedDate)
            let diff = (now.getTime() - date.getTime()) / 1000;
            diff /= (60 * 60);
            diff = Math.round(diff);
            if(diff > hours){
                return true;
            }else{
                return false;
            }
        }
        return true;
    }

    static getCacheAgeCondensedString(name, user){
        let stor = JSON.parse(localStorage.getItem(name + '.' + user.orgId + '.' + user.userId));
        if(!stor){
            return false
        }
        let date = new Date(stor.savedDate)
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years ago";
        }
        interval = Math.round(seconds / 2592000);
        if (interval > 1) {
            return interval + " months ago";
        }
        interval = Math.round(seconds / 86400);
        if (interval > 1) {
            return interval + " days ago";
        }
        interval = Math.round(seconds / 3600);
        if (interval > 1) {
            return interval + " hours ago";
        }
        interval = Math.round(seconds / 60);
        if (interval > 1) {
            return interval + " minutes ago";
        }
        if(Math.round(seconds) < 3){
            return "just now"
        }
        return Math.round(seconds) + " seconds ago";
    }
}